import { Link } from '@remix-run/react';
import { Button } from '~/components/ui/button';
import { formatPrice } from '~/utils/format';
import { GradientBorder } from './gradient-border';
import { Badge } from './ui/badge';

export interface MarketplaceCategory {
  name: string;
  description: string;
  badgeList: Array<{
    value: string;
    label: string;
  }>;
  brandImages: Array<{
    url: string;
    alt: string;
  }>;
  startPrice: number;
  priceFrequency: string;
  ctaButton: {
    label: string;
    href: string;
  };
  hasPreorder: boolean;
  hasOnDemand: boolean;
  hasReserve: boolean;
  hasCluster: boolean;
  categorySlug: {
    value: string;
  };
}
export const CategoryCard: React.FC<{
  category: MarketplaceCategory;
  urlOverride?: string;
}> = ({ category, urlOverride }) => {
  return (
    <div
      key={category.name}
      className="grid grid-cols-1 gap-y-5 rounded-xl border border-purple bg-gradient-to-tr from-card to-purple-darker p-4 sm:p-[30px] md:grid-cols-12 md:gap-x-5 md:gap-y-0"
    >
      <div className="col-span-7 2xl:col-span-8">
        <p className="mb-10 text-[2rem] font-bold">{category.name}</p>
        <div className="mb-[30px] flex flex-wrap gap-2.5 lg:w-4/5">
          {category.badgeList.map((badge) => (
            <Badge key={badge.value} className="rounded-lg bg-[#363250]">
              {badge.label}
            </Badge>
          ))}
        </div>
        <p className="text-purple-light">{category.description}</p>
      </div>

      <div className="md:border-t-none col-span-5 flex h-full flex-col gap-[30px]  border-t border-purple  pt-[30px] md:border-l md:border-t-0 md:pl-[30px] md:pt-0 2xl:col-span-4">
        <div className="flex flex-wrap gap-5 md:gap-4 xl:gap-5">
          {category.brandImages.map((image, index) => (
            <img
              key={index}
              src={image.url}
              alt={image.alt}
              className="h-[50px] w-[100px] lg:h-[45px] lg:w-[90px] xl:h-[50px] xl:w-[100px]"
            />
          ))}
        </div>
        <div className="grid w-full h-full gap-5 2xl:col-span-4">
          <div className="grid w-full h-full grid-cols-5 sm:gap-y-0 md:grid-cols-1 md:gap-y-4 2xl:grid-cols-4">
            <div className="flex flex-col justify-between col-span-3 h-min 2xl:col-span-2">
              <p className="text-sm text-purple-light sm:text-base">
                Starting at
              </p>
              <div className="justify-self-end">
                <span className="text-[1.5rem] text-green sm:text-[2rem]">
                  {formatPrice(Number(category.startPrice))}
                </span>
                <span className="ml-1 text-sm text-purple-light">
                  {category.priceFrequency}
                </span>
              </div>
            </div>

            <div className="flex flex-col justify-between col-span-2 h-min">
              <div className="flex flex-col justify-between h-full sm:self-end md:self-start 2xl:self-end">
                <p className="mb-2 text-sm text-purple-light sm:text-base">
                  Stock available
                </p>
                <div>
                  {category.hasOnDemand && (
                    <p className="text-sm text-green">On demand</p>
                  )}
                  {category.hasReserve && (
                    <p className="text-sm text-blue-light">Reserve</p>
                  )}
                  {category.hasPreorder && (
                    <p className="text-sm text-blue">Pre-order</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Link
            to={urlOverride ?? category.ctaButton.href}
            className="self-end"
          >
            <GradientBorder>
              <Button className="w-full text-base rounded-lg bg-purple-darker text-teal-light">
                {category.ctaButton.label}
              </Button>
            </GradientBorder>
          </Link>
        </div>
      </div>
    </div>
  );
};
